import { StructuredData } from "components/head/StructuredData";
import { SiteFooter } from "components/layout/Footer/SiteFooter";
import { SiteHeader, SiteHeaderColor } from "components/layout/Header/SiteHeader";
import { PropsWithChildren } from "react";
import type { MetaArticleData, MetaPageType } from "@/types/meta";
import { MetaAuthorData } from "@/types/meta";
import dynamic from "next/dynamic";

const PromoBadgeWrap = dynamic(() => import("@/components/banners/PromoBadgeWrap"), { ssr: false });

const PreviewAlert = dynamic(() => import("./PreviewAlert"), { ssr: false });

type PageProps = PropsWithChildren<{
  title?: string;
  description?: string;
  pageType?: MetaPageType;
  headerColor?: SiteHeaderColor;
  articleData?: MetaArticleData;
  isPreview?: boolean | null;
  isBlogPage?: boolean;
  metaReviews?: {
    ratingValue: number;
    reviewCount: number;
  };
  articleReviews?: {
    ratingValue: number;
    reviewCount: number;
  };
  metaThumbnail?: string;
  authorData?: MetaAuthorData;
  extraStructuredDataFields?: Record<string, any>;
  datePublished?: string;
  dateModified?: string;
}>;

export const Page = ({
  children,
  title = "StudyCrumb",
  description = "",
  pageType = "WebPage",
  headerColor,
  articleData,
  isPreview = false,
  isBlogPage,
  metaReviews,
  metaThumbnail,
  authorData,
  extraStructuredDataFields,
  datePublished,
  dateModified,
  articleReviews,
}: PageProps) => {
  return (
    <>
      <StructuredData
        metaData={{ title, description, datePublished, dateModified }}
        pageType={pageType}
        articleData={articleData}
        metaReviews={metaReviews}
        articleReviews={articleReviews}
        metaThumbnail={metaThumbnail}
        authorData={authorData}
        extraFields={extraStructuredDataFields}
      />

      <div className="sc-page">
        <SiteHeader color={headerColor} isBlogPage={isBlogPage} />
        <PromoBadgeWrap />
        <div className="sc-page__inner">{children}</div>

        <SiteFooter isBlogPage={isBlogPage} />

        {isPreview && <PreviewAlert />}
      </div>
    </>
  );
};
